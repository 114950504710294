import { useSelector } from 'react-redux'
import Navbar from '../../../components/nav/Navbar'
import HeaderProfile from '../../../components/nav/HeaderProfile'
import { useStateContext } from '../../../components/contexts/ContextProvider'
import UserSidebar from '../../../components/sidebar/UserSidebar'
import { useEffect, useRef, useState } from 'react'
import BarLoader from 'react-spinners/BarLoader'
import LocalSearch from '../../../components/forms/LocalSearch'
import { MdCheckCircleOutline } from "react-icons/md";
import { FaIdBadge, FaIdCard, FaTriangleExclamation } from "react-icons/fa6";
import { BsFiletypeCsv, BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import moment from 'moment'
import Papa from 'papaparse';
import { TbPasswordMobilePhone } from "react-icons/tb"
import Pagination from '../../../components/pagination/pagination'
import { getAdminAllSubss, getAdminAllUsers, updatePassFrontAdminUser } from '../../../components/functions/user'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { MdClose } from "react-icons/md";


const AdminSubscriptoin = () => {
  const [loadingModal, setLoadingModal] = useState(false)
  const divRef = useRef()
  const user = useSelector((state) => state.user)
  const { activeMenu } = useStateContext()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [keyword, setKeyword] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState({})
  const [successModal, setSuccessModal] = useState('')
  const [errorModal, setErrorModal] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState('All');
  const [filteredData, setFilteredData] = useState([]);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [dataPerPage] = useState(20)

  const dataLists = async () => {
    try {
      setLoading(true)
      const res = await getAdminAllSubss(user?.token, setError)
      if (res && res.status === 200) {
        setData(res?.data)
        filterDataByPlan(selectedPlan, res?.data);
      }
      setLoading(false)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    dataLists()
  }, [])

  useEffect(() => {
    setFilteredData(data.filter(searched(keyword)));
  }, [data, keyword]);


  const searched = (keyword) => (d) =>
    d?.user?.email?.toLowerCase().includes(keyword) ||
    d?.user?.name?.toLowerCase().includes(keyword)


  //Get current data
  const indexOfLastPost = currentPage * dataPerPage
  const indexOfFirstPost = indexOfLastPost - dataPerPage
  const currentData = filteredData.slice(indexOfFirstPost, indexOfLastPost)
  const howManyPages = Math.ceil(filteredData.length / dataPerPage)
  


  const closeModal = () => {
    setErrorModal('')
    setSuccessModal('')
    setSelectedUser({})
    setShowModal(false)
  }

  const handleSelecteUser = (dt) => {
    setSelectedUser(dt)
    setShowModal(true)
  }

  const filterDataByPlan = (planType) => {
    if (planType === 'All') {
      setFilteredData(data.filter(searched(keyword)));

    } else {
      const filtered = data.filter((item) => item?.plan?.plan === planType?.toLowerCase());
      setFilteredData(filtered.filter(searched(keyword)));
    }

  };


  const handleExport = () => {
    try {
      const filteredData = data?.filter(item => item.user && item.plan);
  
      if (!filteredData || filteredData?.length === 0) {
        throw new Error('No valid data to export.');
      }
  
      const csvData = filteredData.map(item => ({
        Name: item.user.name || 'N/A',
        Email: item.user.email || 'N/A',
        Plan: item.plan.plan === 'basicmonthly599' ? 'Basic' :
              item.plan.plan === 'premiummonthly999' ? 'Premium' :
              item.plan.plan === 'no_plan' ? 'Expired' : 'Free'
      }));
  
      const csv = Papa.unparse(csvData);
  
      const blob = new Blob([csv], { type: 'text/csv' });
  
      const monthNames = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
      const currentMonthIndex = new Date().getMonth();
      const currentMonth = monthNames[currentMonthIndex];
  
      const randomNumber = Math.floor(Math.random() * 10000);
      const filename = `user_subs_data_${currentMonth}_${randomNumber}.csv`;
  
      const url = window.URL.createObjectURL(blob);
  
      const link = document.createElement('a');
  
      link.href = url;
  
      link.setAttribute('download', filename);
  
      link.click();
  
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting data:', error);
  
      toast.error('Error exporting data. Please try again.');
    }
  };
  
  
  
  



  return (
    <>
      <div className="flex relative container mx-auto pt-1">
        {activeMenu ? (
          <div className="w-72 sidebar dark:bg-secondary-dark-bg bg-white ">
            <UserSidebar />
          </div>
        ) : (
          <div className="w-0 dark:bg-secondary-dark-bg">
            <UserSidebar />
          </div>
        )}
        <div
          className={
            activeMenu
              ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
              : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
          }
        >
          <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
            <Navbar />
          </div>
          <div>
            <div className="m-2 md:ml-8 p-2 md:p-1 bg-white rounded-1xl">
              <HeaderProfile title={`Subscriptions`} />

              {loading && (
                <div className="flex justify-center items-center">
                  <BarLoader color="#fe0002" loading={loading} size={20} />
                </div>
              )}
              {!loading && (
                <div className="overflow-x-auto">
                  {data && data?.length === 0 && (
                    <div className="flex justify-center items-center">
                      <p className="text-slate-900">No data found</p>
                    </div>
                  )}
                  
                    <div className="flex flex-col md:flex-row justify-between items-center">
                      <div className="max-w-2xl flex justify-between items-center">
                        <LocalSearch
                          keyword={keyword}
                          setKeyword={setKeyword}
                        />
                        <button onClick={handleExport} className="bg-customRed hover:bg-red-900 flex text-white font-bold py-2 px-4 rounded -mt-[9px] ml-4">
                           <BsFiletypeCsv color="#fff" size={22} /> <span className='ml-2'>Export </span>
                        </button>

                        
                      </div>

                      

                      <div className="max-w-2xl flex-shrink-0 flex items-center">
                        <p className="mr-2">Filter by plan:</p>
                        <select
                          className="border border-gray-300 rounded-md py-1 px-2"
                          onChange={(e) => {
                            setSelectedPlan(e.target.value);
                            filterDataByPlan(e.target.value);
                          }}
                          value={selectedPlan}
                        >
                          <option value="All">All</option>
                          <option value="free">Free</option>
                          <option value="basicmonthly599">Basic</option>
                          <option value="premiummonthly999">Premium</option>
                          <option value="no_plan">Expired</option>
                        </select>
                      </div>

                      
                    </div>
                 

                  <div className="space-y-4">
                    <div className="w-full">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Name
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Email
                            </th>

                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Plan
                            </th>
                            
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {currentData?.map((dt, i) =>   {
                            return (
                              <tr key={i}>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dt?.user?.name}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dt?.user?.email}
                              </td>

                              <td className="px-6 py-4 whitespace-nowrap">
                              

                                {dt?.plan?.plan === 'basicmonthly599'
                                    ? 'Basic'
                                    : dt?.plan?.plan === 'premiummonthly999'
                                    ? 'Premium'
                                    : dt?.plan?.plan === 'no_plan' ? 'Expired' : 'Free' }
                              </td>
                              
                              <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                                <button data-tooltip-id={`showsubs-${dt?.user?._id}`} onClick={() => handleSelecteUser(dt)} className='text-red-600'>
                                  <FaIdCard size="24" /> 
                                  <ReactTooltip
                                    id={`showsubs-${dt?.user?._id}`}
                                    place="top"
                                    effect="solid"
                                  >
                                    Show subscription
                                  </ReactTooltip>
                                </button>
                              </td>
                            </tr>
                            )
                          }
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>


                  {showModal && (
                    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-800 bg-opacity-50 overflow-y-scroll">
                      <div
                        ref={divRef}
                        className="bg-white rounded-lg shadow-lg sm:w-4/5 md:w-1/2 pb-2 overflow-y-auto max-h-[100vh]"
                      >
                        <div className="bg-gray-100 border-b px-4 py-6 flex justify-between items-center rounded-lg">
                          <h3 className="font-semibold text-xl text-stone-600">
                            Subscription information
                          </h3>

                          <div className="flex">
                            <button
                              onClick={closeModal}
                              className="text-black close-modal"
                            >
                             <MdClose size="24" />
                            </button>
                          </div>
                        </div>

                        <div className="p-6">
                          <div className="relative w-full">
                            <div className="flex justify-center items-center h-1 absolute w-full">
                              {loadingModal && (
                                <div className="flex-row justify-center items-center">
                                <BarLoader color="#fe0002" loading={loadingModal} size={20} />
                              </div>
                              )}

                              {successModal && (
                                <h5 className="text-center text-green-500 mt-3 text-success flex justify-center items-center">
                                  <MdCheckCircleOutline style={{
                                    marginRight: 5
                                  }} />
                                  {successModal}
                                </h5>
                              )}
                              {errorModal && (
                                <h5 className="text-center text-red-400 my-3 text-danger flex justify-center items-center">
                                  <FaTriangleExclamation style={{
                                    marginRight: 5
                                  }} />{' '}
                                  {errorModal}
                                </h5>
                              )}
                            </div>
                          </div>


                          <div className="my-2">
                            <div className="mb-4">
                              <p
                                className="block text-sm font-medium text-gray-700 my-2"
                              >
                                User name
                              </p>
                              <p className="block w-full py-1 px-2 text-lg rounded-sm bg-slate-100 border border-stone-200  outline-none">
                                {selectedUser?.user?.name}
                              </p>
                            </div>

                            <div className="mb-4">
                              <p
                                className="block text-sm font-medium text-gray-700 my-2"
                              >
                                User email
                              </p>
                              <p className="block w-full py-1 px-2 text-lg rounded-sm bg-slate-100 border border-stone-200  outline-none">
                                {selectedUser?.user?.email}
                              </p>
                            </div>

                            <br />
                            
                            <div className="mb-4">
                              <p className="block text-lg font-medium text-gray-700 my-2">
                                Subscription plan
                              </p>
                              <div className="flex justify-between items-center my-2">
                                <p className="text-md font-medium text-gray-900">Plan</p>
                                <p className="block py-1 px-2 text-lg rounded-sm bg-slate-100 border border-stone-200 outline-none w-[100px] md:w-[200px]">
                                
                                  {selectedUser?.plan?.plan === 'basicmonthly599'
                                    ? 'Basic'
                                    : selectedUser?.plan?.plan === 'premiummonthly999'
                                    ? 'Premium'
                                    : selectedUser?.plan?.plan === 'no_plan' ? 'Expired' : 'Free' }
                                </p>
                              </div>

                              <div className="flex justify-between items-center my-2">
                                <p className="text-md font-medium text-gray-900">Available scans</p>
                                <p className="block py-1 px-2 text-lg rounded-sm bg-slate-100 border border-stone-200 outline-none w-[100px] md:w-[200px] ">
                                  {selectedUser?.plan?.receiptsScanned}
                                </p>
                              </div>

                              <div className="flex justify-between items-center my-2">
                                <p className="text-md font-medium text-gray-900">Scanned</p>
                                <p className="block py-1 px-2 text-lg rounded-sm bg-slate-100 border border-stone-200 outline-none w-[100px] md:w-[200px] ">
                                  {selectedUser?.plan?.alreadyScanned}
                                </p>
                              </div>

                              

                              <div className="flex justify-between items-center my-2">
                                <p className="text-md font-medium text-gray-900">Expiration date</p>
                                <p className="block py-1 px-2 text-lg rounded-sm bg-slate-100 border border-stone-200 outline-none w-[100px] md:w-[200px] ">
                                  {selectedUser?.plan?.currentPeriodEnd ? moment(selectedUser?.plan?.currentPeriodEnd, 'unix').format('YYYY-MM-DD HH:mm:ss') : 'N/A'}
                                </p>
                              </div>

                              <hr />

                              <div className="flex justify-between items-center my-2">
                                <p className="text-md font-medium text-gray-900">Planform</p>
                                <p className="block py-1 px-2 text-lg rounded-sm bg-slate-100 border border-stone-200 outline-none w-[100px] md:w-[200px] ">
                                  {selectedUser?.plan?.platform ? selectedUser?.plan?.platform : 'N/A'}
                                </p>
                              </div>

                              <div className="flex justify-between items-center my-2">
                                <p className="text-md font-medium text-gray-900">Payment method</p>
                                <p className="block py-1 px-2 text-lg rounded-sm bg-slate-100 border border-stone-200 outline-none w-[100px] md:w-[200px] ">
                                  {selectedUser?.plan?.paymentMethod ? selectedUser?.plan?.paymentMethod : 'N/A'}
                                </p>
                              </div>

                              <div className="flex justify-between items-center my-2">
                                <p className="text-md font-medium text-gray-900">Last transaction ID</p>
                                <p className="block py-1 px-2 text-lg rounded-sm bg-slate-100 border border-stone-200 outline-none w-[100px] md:w-[200px] ">
                                  {selectedUser?.plan?.transactionId ? selectedUser?.plan?.transactionId : 'N/A'}
                                </p>
                              </div>

                              
                              
                            </div>



                          </div>

                          
                        </div>
                      </div>
                    </div>
                  )}

                  {!loading && currentData && currentData?.length > 0 && (
                    <div className="py-20">
                      <Pagination
                        pages={howManyPages}
                        setCurrentPage={setCurrentPage}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminSubscriptoin
