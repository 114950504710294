import React from 'react';
import { FaAndroid, FaApple } from 'react-icons/fa';
import Typed from 'react-typed';

const Hero = () => {
  const smoothScroll = (id) => (e) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const backgroundUrl =
    'https://res.cloudinary.com/dwn02nfdv/image/upload/v1704031534/xt1qkkshc2fmjl6rkna4.jpg';

  return (
    <div
      className="relative text-white bg-fixed bg-cover bg-center overflow-hidden"
      style={{
        backgroundImage: `url(${backgroundUrl})`,
        minHeight: '75vh',
      }}
      id="home"
    >
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      <div className="relative w-full h-full flex flex-col justify-center items-center text-center pt-48">
        <h1 className="md:text-4xl sm:text-2xl text-2xl font-bold md:py-6 uppercase">
          Welcome to Sima-Scan
        </h1>
        <p className="md:text-2xl sm:text-md text-xl font-bold py-4">
          Developed to scan and extract detailed information
        </p>

        <Typed
          className="md:text-2xl sm:text-4xl text-xl font-bold md:pl-4 pl-2"
          strings={[
            'Automatic Data Extraction',
            'Lost receipt, you are covered',
            'Expense report for tax purpose, you are covered',
            'Track your expenses, you are also covered',
            'Snap, scan and save expense receipt on-the-go',
            'Time-saving Technology',
            'Reliable and Efficient',
          ]}
          typeSpeed={30}
          backSpeed={40}
          loop
        />

<div className="flex flex-col sm:flex-row justify-center items-center gap-4">
        <a
          href="https://apps.apple.com/ca/app/sima-scan/id6476885580"
          target="_blank"
          rel="noopener noreferrer"
         
          className="download-button my-6 w-[230px] inline-flex items-center justify-center text-white bg-customRed hover:bg-red-600 text-md px-3 py-2 focus:outline-none"
          style={{ width: '300px' }}
        >
          <FaApple />
          <span className='ml-2'>Download for iOS</span>
        </a>

        <a
          href="https://play.google.com/store/apps/details?id=com.simascanreceiptinvoice"
          target="_blank"
          rel="noopener noreferrer"
         
          className="download-button my-6 w-[230px] inline-flex items-center justify-center text-white bg-customRed hover:bg-red-600 text-md px-3 py-2 focus:outline-none"
          style={{ width: '300px' }}
        >
          <FaAndroid />
          <span className='ml-2'>Download for Android</span>
        </a>
      </div>

        
      </div>
    </div>
  );
};

export default Hero;
