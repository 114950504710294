import React from 'react';

const HowItWorks = () => {
  return (
    <div className=" bg-gray-50 py-4 text-center" id="howItWorks">
      <div className="container mx-auto py-20">
        <h2 className="text-3xl font-bold mb-4">How it works?</h2>

        <div className="flex flex-col md:flex-row justify-center items-center gap-10 px-4 pt-16">
          <div className="w-full md:w-1/2">
            <h3 className="my-5 font-bold text-xl">
              Scanning receipts with Sima-Scan App
            </h3>

            <video width="100%" controls muted>
              <source
                src="https://res.cloudinary.com/dwn02nfdv/video/upload/v1703949396/qesdiqwm630nvby3nesu.mov"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="w-full md:w-1/2">
            <h3 className="my-5 font-bold text-xl">
              Organizing and tracking all your expenses.
            </h3>
            <video width="100%" controls muted>
              <source
                src="https://res.cloudinary.com/diyrdsvtq/video/upload/f_auto:video,q_auto/v1/sima/vx9mvdaupxuzpszupl9o"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className="flex flex-col items-center md:w-full mx-auto mt-24 px-3">
          <p className="text-lg font-bold mb-2">Scan your receipts / invoice</p>
          <ul className="list-disc pl-4 text-left">
            <li>
              Scan your receipts using the camera or upload from your photo
              library
            </li>
            <li>Automatically extract and organize data</li>
            <li>Generate reports and track expenses easily</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;