/* eslint-disable no-loop-func */
import React, { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getUser } from '../../components/utils/api';
import Papa from 'papaparse';
import moment from 'moment';
import BarLoader from 'react-spinners/BarLoader';
import { BsFileEarmarkPdfFill, BsFiletypeCsv } from 'react-icons/bs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';
const companyLogo = require('../../assets/sima-logo.png');

const Report = () => {
  const { code, id } = useParams();
  const [rcpt, setRcpt] = useState({});
  const [error, setError] = useState('');
  const [linkExp, setLinkExp] = useState(false);
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);

  const loadUser = () => {
    setError('');
    setSuccess('');
    setLinkExp(false);
    setLoading(true);

    getUser(code, id)
      .then((res) => {
        if (res.status === 'ok') {
          setRcpt(res?.data?.receiptsData);
        }
        if (
          res === 'No user found' ||
          res === 'Something went wrong' ||
          res === 'Invalid link' ||
          res === 'Your link has been expired'
        ) {
          setError('Invalid link');
        }

        if (res === 'Your link has been expired') {
          setLinkExp(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
        setError('');
        setSuccess('');
        setLoading(false);
      });
  };

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateCSV = (data) => {
    const formattedData = [];

    // Step 1: Group the data by month
    const groupedData = data.reduce((acc, receipt) => {
      console.log('Receipt:', receipt);
      const month = moment(receipt.date).format('MMMM');
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(receipt);
      return acc;
    }, {});

    for (const month in groupedData) {
      if (groupedData.hasOwnProperty(month)) {
        const monthData = groupedData[month];
        const totalAmount = monthData.reduce(
          (total, receipt) => total + receipt.totalAmount,
          0
        );

        monthData.forEach((receipt) => {
          formattedData.push({
            'Supplier name': receipt.supplierName,
            'Items name': '',
            'Items price': '',
            Total: `$${receipt.totalAmount.toFixed(2)}`,
            Category: receipt.selectedCategory
              ? receipt.selectedCategory.name
              : 'Uncategorized',
            Date: moment(receipt.date).format('MM-DD-YYYY'),
            Time: receipt.time,
          });

          receipt.lineItems?.forEach((line) => {
            formattedData.push({
              'Supplier name': '',
              'Items name': line.description, // Item name
              'Items price': `$${line.total_amount}`, // Item price
              Total: '',
              Date: '',
              Time: '',
            });
          });

          // Add tax as an item at the end of line items
          formattedData.push({
            'Supplier name': '',
            'Items name': 'Tax',
            'Items price': `$${receipt.taxAmount.toFixed(2)}`, // Tax amount for receipt
            Total: '',
            Date: '',
            Time: '',
          });

          formattedData.push({
            'Supplier name': '', // Empty row after line items
            'Items name': '',
            'Items price': '',
            Total: '',
            Date: '',
            Time: '',
          });
        });

        formattedData.push({
          'Supplier name': `${month} total`,
          'Items name': '',
          'Items price': '',
          Total: `$${totalAmount.toFixed(2)}`,
          Date: '',
          Time: '',
        });

        if (month !== Object.keys(groupedData).slice(-1)[0]) {
          formattedData.push({
            'Supplier name': '',
            'Items name': '',
            'Items price': '',
            Total: '',
            Date: '',
            Time: '',
          });
        }
      }
    }

    // Calculate the total for all months
    const totalAllMonths = data.reduce(
      (total, receipt) => total + receipt.totalAmount,
      0
    );

    // Add an empty row before the total of all months
    formattedData.push({
      'Supplier name': '',
      'Items name': '',
      'Items price': '',
      Total: '',
      Date: '',
      Time: '',
    });

    // Add the row for the total of all months
    formattedData.push({
      'Supplier name': 'Total All Months',
      'Items name': '',
      'Items price': '',
      Total: `$${totalAllMonths.toFixed(2)}`,
      Date: '',
      Time: '',
    });

    const csv = Papa.unparse(formattedData, {
      header: true,
    });

    return csv;
  };

  const handleDownload = () => {
    const randomReceiptNumber = Math.floor(Math.random() * 10000) + 1;
    const filename = `receipt-${randomReceiptNumber}.csv`;

    const csvContent = generateCSV(rcpt);

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const generatePDF = (data) => {
    const pdf = new jsPDF();
    let y = 10;

    const groupedData = data.reduce((acc, receipt) => {
      const month = moment(receipt.date).format('MMMM');
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(receipt);
      return acc;
    }, {});

    for (const month in groupedData) {
      if (groupedData.hasOwnProperty(month)) {
        const monthData = groupedData[month];
        const totalAmount = monthData.reduce(
          (total, receipt) => total + receipt.totalAmount,
          0
        );

        pdf.setFontSize(18);
        pdf.text(month, 10, y);
        y += 10;

        monthData.forEach((receipt) => {
          pdf.setFontSize(12);
          pdf.text('Supplier Name: ' + receipt.supplierName, 15, y);
          y += 7;

          pdf.text('Total: $' + receipt.totalAmount.toFixed(2), 15, y);
          y += 7;
          if (receipt.selectedCategory) {
            pdf.text('Category: ' + receipt.selectedCategory.name, 15, y);
            y += 7;
          }

          pdf.text('Date: ' + moment(receipt.date).format('MM-DD-YYYY'), 15, y);
          y += 7;

          pdf.text('Time: ' + receipt.time, 15, y);
          y += 7;

          if (receipt.lineItems) {
            receipt.lineItems.forEach((line) => {
              pdf.text('Item Name: ' + line.description, 20, y);
              pdf.text('Item Price: $' + line.total_amount, 100, y);
              y += 7;
            });
          }
          pdf.text('Item Name: Tax', 20, y);
          pdf.text('Item Price: $' + receipt.taxAmount.toFixed(2), 100, y);
          y += 7;

          y += 7;
        });

        pdf.setFontSize(12);
        pdf.text(`Total: $${totalAmount.toFixed(2)}`, 15, y);
        y += 10;
      }
    }

    const totalAllMonths = data.reduce(
      (total, receipt) => total + receipt.totalAmount,
      0
    );
    pdf.setFontSize(18);
    pdf.text('Total All Months: $' + totalAllMonths.toFixed(2), 10, y);

    pdf.save('report.pdf');
  };

  const handleDownloadPDF = () => {
    setPdfLoading(true);
    generatePDF(rcpt)
      ?.then((formattedData) => {
        const pdf = new jsPDF('p', 'mm', 'a4');
        pdf.save('receipt.pdf');
        setPdfLoading(false);
      })
      .catch((error) => {
        console.error('Error generating PDF:', error);
        setPdfLoading(false);
      });
  };

  return (
    <div className="flex flex-col items-center">
      {loading && (
        <div className="flex justify-center items-center h-screen">
          <BarLoader color="red" loading={loading} size={30} />
        </div>
      )}

      {!loading && error && (
        <div className="flex justify-center items-center h-screen">
          <div className="w-full max-w-md p-4 bg-white rounded shadow-lg">
            {linkExp && (
              <>
                <div className="flex justify-center items-center">
                  <img src={companyLogo} alt="Logo" className="w-24 my-2" />
                </div>
                <h5 className="text-center text-red-500 text-xl mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 inline-block mb-1 mr-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 7h2v4h-2zm0 4h2v2h-2z"
                    />
                  </svg>
                  Your link has been expired
                </h5>
              </>
            )}
            <div className="relative h-48">
              <img
                alt="404"
                src="https://unsplash.com/photos/BwMcYuHI9OI/download?ixid=MnwxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNjcwODM4MTk5&force=true&w=640"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="text-center mt-4">
              <h2 className="text-2xl font-bold mb-2">
                {linkExp ? 'Link expired' : 'Invalid link'}
              </h2>
              <p>
                {linkExp
                  ? 'This link has been expired, please generate new report.'
                  : "We didn't find the link you are looking for"}
              </p>
            </div>
          </div>
        </div>
      )}
      {!loading && !error && (
        <div className="flex justify-center items-center h-screen">
          <div className="w-full max-w-md p-4 bg-white rounded shadow-lg">
            <div className="flex justify-center items-center">
              <img src={companyLogo} alt="Logo" className="w-24 my-6" />
            </div>
            <div className="flex justify-between">
              <button
                onClick={handleDownload}
                className="mr-2 bg-customRed hover:bg-red-900 text-white font-bold py-2 px-4 rounded flex justify-center items-center"
              >
                <BsFiletypeCsv color="#fff" size={20} />
                <span className="ml-2">Download CSV</span>
              </button>
              <button
                onClick={handleDownloadPDF}
                className="bg-customRed hover:bg-red-900 text-white font-bold py-2 px-4 rounded flex justify-center items-center"
              >
                <BsFileEarmarkPdfFill color="#fff" size={20} />
                <span className="ml-2">Download PDF</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Report;
