import { useSelector } from 'react-redux'
import Navbar from '../../../components/nav/Navbar'
import HeaderProfile from '../../../components/nav/HeaderProfile'
import { useStateContext } from '../../../components/contexts/ContextProvider'
import UserSidebar from '../../../components/sidebar/UserSidebar'
import { useEffect, useRef, useState } from 'react'
import BarLoader from 'react-spinners/BarLoader'
import LocalSearch from '../../../components/forms/LocalSearch'
import { MdCheckCircleOutline } from "react-icons/md";
import { FaTriangleExclamation } from "react-icons/fa6";
import { BsFiletypeCsv, BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import Papa from 'papaparse';



import { TbPasswordMobilePhone } from "react-icons/tb"
import Pagination from '../../../components/pagination/pagination'
import { getAdminAllUsers, updatePassFrontAdminUser } from '../../../components/functions/user'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { MdClose } from "react-icons/md";


const AdminUsers = () => {
  const [loadingModal, setLoadingModal] = useState(false)
  const divRef = useRef()
  const user = useSelector((state) => state.user)
  const { activeMenu } = useStateContext()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [keyword, setKeyword] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState({})
  const [successModal, setSuccessModal] = useState('')
  const [errorModal, setErrorModal] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  // Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [dataPerPage] = useState(20)

  const dataLists = async () => {
    try {
      setLoading(true)
      const res = await getAdminAllUsers(user?.token, setError)
      if (res.status === 200) {
        setData(res?.data)
      }
      setLoading(false)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    dataLists()
  }, [])


  const searched = (keyword) => (d) =>
    d?.email.toLowerCase().includes(keyword) ||
    d?.name.toLowerCase().includes(keyword)

  const filteredData = data.filter(searched(keyword))

  //Get current data
  const indexOfLastPost = currentPage * dataPerPage
  const indexOfFirstPost = indexOfLastPost - dataPerPage
  const currentData = filteredData.slice(indexOfFirstPost, indexOfLastPost)
  const howManyPages = Math.ceil(filteredData.length / dataPerPage)

  const handleCopy = (reportCode, id) => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_FRONTEND_URL}generated-report/${reportCode}/${id}`
    );
    toast.success('Link Copied')
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm()


  const closeModal = () => {
    setErrorModal('')
    setSuccessModal('')
    setValue('name', '')
    setValue('email', '')
    setValue('password', '')
    setShowModal(false)
  }

  const handleSelecteUser = (dt) => {
    setSelectedUser(dt)
    setValue('name', dt?.name)
    setValue('email', dt?.email)
    setShowModal(true)
  }

  const updateUserPassword = async (data) =>{
    const {password, email} = data

    const newData = {
      password, email
    }

    try {
      setLoadingModal(true)
      const res = await updatePassFrontAdminUser(newData, user?.token, setErrorModal)
      if (res && res.status === 200) {
        setSuccessModal(res.data.message)
        reset()
        setTimeout(() => {
          closeModal()
        }, 3000);
        
      }
      setLoadingModal(false)
    } catch (error) {
      console.log(error);
      setLoadingModal(false)
    }

  }


  const handleExport = () => {
    try {
      const filteredData = data?.filter(item => item);

      console.log(filteredData)
  
      if (!filteredData || filteredData?.length === 0) {
        throw new Error('No valid data to export.');
      }
  
      const csvData = filteredData.map(item => ({
        "Name:": item.name || 'N/A',
        "Email:": item.email || 'N/A',
        "Reset Code:": item.resetCode || 'N/A', 
        "Report Link:": item?.reportCode ? `${process.env.REACT_APP_FRONTEND_URL}generated-report/${item?.reportCode}/${item?._id}` : 'N/A',
      }));
  
      const csv = Papa.unparse(csvData);
  
      const blob = new Blob([csv], { type: 'text/csv' });
  
      const timestamp = Date.now();
      const filename = `user_data_${timestamp}.csv`;
  
      const url = window.URL.createObjectURL(blob);
  
      const link = document.createElement('a');
  
      link.href = url;
  
      link.setAttribute('download', filename);
  
      link.click();
  
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting data:', error);
    
      if (!data) {
        toast.error('Data is null or undefined. Please check your data source.');
      } else if (!data.some(item => item.user)) {
        toast.error('No objects with a user property found in data. Please check your data structure.');
      } else if (data.some(item => !item.user.name || !item.user.email || !item.user.resetCode || !item.user.reportLink)) {
        toast.error('Some user objects are missing required properties. Please check your data structure.');
      } else {
        toast.error('An error occurred during the CSV generation or file download process. Please try again.');
      }
    }
  };

  return (
    <>
      <div className="flex relative container mx-auto pt-1">
        {activeMenu ? (
          <div className="w-72 sidebar dark:bg-secondary-dark-bg bg-white ">
            <UserSidebar />
          </div>
        ) : (
          <div className="w-0 dark:bg-secondary-dark-bg">
            <UserSidebar />
          </div>
        )}
        <div
          className={
            activeMenu
              ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
              : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
          }
        >
          <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
            <Navbar />
          </div>
          <div>
            <div className="m-2 md:ml-8 p-2 md:p-1 bg-white rounded-1xl">
              <HeaderProfile title={`Users`} />

              {loading && (
                <div className="flex justify-center items-center">
                  <BarLoader color="#fe0002" loading={loading} size={20} />
                </div>
              )}
              {!loading && (
                <div className="overflow-x-auto">
                  {data && data.length === 0 && (
                    <div className="flex justify-center items-center">
                      <p className="text-slate-900">No data found</p>
                    </div>
                  )}
                  {data && data.length > 0 && (
                    <div className="flex flex-col md:flex-row justify-between items-center">
                      <div className="max-w-2xl flex justify-between items-center">
                        <LocalSearch
                          keyword={keyword}
                          setKeyword={setKeyword}
                        />
                        <button onClick={handleExport} className="bg-customRed hover:bg-red-900 flex text-white font-bold py-2 px-4 rounded -mt-[9px] ml-4">
                           <BsFiletypeCsv color="#fff" size={22} /> <span className='ml-2'>Export </span>
                        </button>

                        
                      </div>

                      
                    </div>
                  )}

                  <div className="space-y-4">
                    <div className="w-full">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Name
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Email
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Reset Code
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Report Link
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {currentData?.map((dt) => (
                            <tr key={dt?._id}>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dt?.name}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dt?.email}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dt?.verifyOtp}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-center">
                                {dt?.reportCode 
                                && 
                                <button data-tooltip-id={`reporyCopy-${dt?._id}`} className='border border-slate-300 px-3 py-1 rounded-md text-xs' onClick={() => handleCopy(dt?.reportCode, dt._id)}>
                                  Copy
                                  <ReactTooltip
                                    id={`reporyCopy-${dt._id}`}
                                    place="top"
                                    effect="solid"
                                  >
                                    Copy
                                  </ReactTooltip>
                                </button>
                                }
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                                <button data-tooltip-id={`userUpdatePass-${dt?._id}`} onClick={() => handleSelecteUser(dt)} className='text-red-600'>
                                  <TbPasswordMobilePhone size="24" /> 
                                  <ReactTooltip
                                    id={`userUpdatePass-${dt._id}`}
                                    place="top"
                                    effect="solid"
                                  >
                                    Update user password
                                  </ReactTooltip>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>


                  {showModal && (
                    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-800 bg-opacity-50">
                      <div
                        ref={divRef}
                        className="bg-white rounded-lg shadow-lg w-full sm:w-4/5 md:w-1/2 pb-2"
                      >
                        <div className="bg-gray-100 border-b px-4 py-6 flex justify-between items-center rounded-lg">
                          <h3 className="font-semibold text-xl text-stone-600">
                            Update user password from admin
                          </h3>

                          <div className="flex">
                            <button
                              onClick={closeModal}
                              className="text-black close-modal"
                            >
                             <MdClose size="24" />
                            </button>
                          </div>
                        </div>

                        <div className="p-6">
                          <div className="relative w-full">
                            <div className="flex justify-center items-center h-1 absolute w-full">
                              {loadingModal && (
                                <div className="flex-row justify-center items-center">
                                <BarLoader color="#fe0002" loading={loadingModal} size={20} />
                              </div>
                              )}

                              {successModal && (
                                <h5 className="text-center text-green-500 mt-3 text-success flex justify-center items-center">
                                  <MdCheckCircleOutline style={{
                                    marginRight: 5
                                  }} />
                                  {successModal}
                                </h5>
                              )}
                              {errorModal && (
                                <h5 className="text-center text-red-400 my-3 text-danger flex justify-center items-center">
                                  <FaTriangleExclamation style={{
                                    marginRight: 5
                                  }} />{' '}
                                  {errorModal}
                                </h5>
                              )}
                            </div>
                          </div>

                          <form
                            onSubmit={handleSubmit(updateUserPassword)}
                            className="m-5"
                          >
                            
                            <div className="mb-4">
                              <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-700 my-2"
                              >
                                User name
                              </label>
                              <input
                                id="name"
                                type="text"
                                {...register('name')}
                                className="block w-full py-1 px-2 text-lg rounded-sm bg-slate-200 border border-stone-200  outline-none"
                                disabled
                              />
                              
                            </div>

                            <div className="mb-4">
                              <label
                                htmlFor="email"
                                className="block text-sm font-medium text-gray-700 my-2"
                              >
                                User email
                              </label>
                              <input
                                id="email"
                                type="email"
                                {...register('email')}
                                className="block w-full py-1 px-2 text-lg rounded-sm bg-slate-200 border border-stone-200  outline-none"
                                disabled
                              />
                              
                            </div>

                            <div className="mb-4 relative">
                              <label
                                htmlFor="password"
                                className="block text-sm font-medium text-gray-700 my-2"
                              >
                                Update user password
                              </label>
                              <input
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                {...register('password', {
                                  required: 'Password is required',
                                })}
                                className="block w-full py-1 px-2 text-lg rounded-sm bg-white border border-stone-200  outline-none"
                              />

                              <div
                                  className="absolute right-4 top-10"
                                    
                                  onClick={() => setShowPassword(!showPassword)}
                                  >
                                {showPassword ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
                              </div>
                              
                            </div>

                            {errors.password && (
                              <p className="text-red-500 text-xs mt-1">
                                {errors.password.message}
                              </p>
                            )}

                            <div className="flex justify-start mt-10">
                              <button
                                className={`bg-customRed hover:bg-red-500 text-white font-bold py-2 rounded mr-10 px-10 ${
                                  loadingModal && 'ActionButton'
                                }`}
                                type="submit"
                              >
                                Update
                              </button>

                              <button
                                type="button"
                                onClick={closeModal}
                                className=" text-red-500 font-bold py-2 px-10 rounded border-red-500 border"
                                disabled={loadingModal}
                              >
                                Cancel
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}

                  {!loading && currentData && currentData?.length > 0 && (
                    <div className="py-20">
                      <Pagination
                        pages={howManyPages}
                        setCurrentPage={setCurrentPage}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminUsers
