import axios from 'axios';
import catchErrors from '../../utils/catchErrors';

export const userFrontendAccountDelete = async (newdata, setError) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}user-account-del-request`,
      newdata
    );
    setError(null);
    return res;
  } catch (error) {
    const errorMsg = catchErrors(error);
    setError(errorMsg);
  }
};

export const userFrontendAccountDeleteConfirm = async (newdata, setError) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}user-account-del-request-confirm`,
      newdata
    );
    setError(null);
    return res;
  } catch (error) {
    const errorMsg = catchErrors(error);
    setError(errorMsg);
  }
};

export const loginUser = async (newdata, setError) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}login-web`,
      newdata
    );
    setError(null);
    return res;
  } catch (error) {
    const errorMsg = catchErrors(error);
    setError(errorMsg);
  }
};

export const emailRequestForget = async (newdata, setError) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}user-forgot-password-email`,
      newdata
    );
    setError(null);
    return res;
  } catch (error) {
    const errorMsg = catchErrors(error);
    setError(errorMsg);
  }
};

export const forgetPasswordCodeCheck = async (newdata, setError) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}user-forgot-password-otp`,
      newdata
    );
    setError(null);
    return res;
  } catch (error) {
    const errorMsg = catchErrors(error);
    setError(errorMsg);
  }
};

export const forgetPasswordUpdateRequest = async (newdata, setError) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}user-forgot-password-update`,
      newdata
    );
    setError(null);
    return res;
  } catch (error) {
    const errorMsg = catchErrors(error);
    setError(errorMsg);
  }
};

export const getAdminAllUsers = async (token, setError) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setError(null);
    return res;
  } catch (error) {
    const errorMsg = catchErrors(error);
    setError(errorMsg);
  }
};

export const getAdminAllSubss = async (token, setError) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}users-subs`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setError(null);
    return res;
  } catch (error) {
    const errorMsg = catchErrors(error);
    setError(errorMsg);
  }
};

export const sendEmailContact = async (newdata, setError) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}send-email`,
      newdata
    );
    setError(null);
    return res;
  } catch (error) {
    const errorMsg = catchErrors(error);
    setError(errorMsg);
  }
};

export const updatePassFrontAdminUser = async (newdata, token, setError) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}user-password-update-admin`,
      newdata,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setError(null);
    return res;
  } catch (error) {
    const errorMsg = catchErrors(error);
    setError(errorMsg);
  }
};

export const updateUserPassword = async (data, authtoken, setError) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}user-password-update`,
      data,
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    );
    setError(null);
    return res;
  } catch (error) {
    const errorMsg = catchErrors(error);
    setError(errorMsg);
  }
};
